import React from 'react'
import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* latin */
			@font-face {
				font-family: "Anything Script_1";
				src: url("../../fonts/AnythingScript1-0W8Er.ttf");
				font-display: swap;
			}
			@font-face {
				font-family: "Photograph Signature";
				src: url("../../fonts/Photograph\ Signature.ttf");
				font-display: swap;
			}
      `}
  />
)

export default Fonts