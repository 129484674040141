import React from 'react'
import Fonts from './src/fonts/Fonts'
import "@fontsource/abhaya-libre"
import "@fontsource/pinyon-script"
import "@fontsource/mate-sc"
import {
  ChakraProvider
} from '@chakra-ui/react'
import theme from './src/@chakra-ui/gatsby-plugin/theme'

export const wrapRootElement = ({ element }) => {
	return (
		<ChakraProvider theme={theme}>
			<Fonts/>
			{element}
		</ChakraProvider>
	)
}