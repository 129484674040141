import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    heading: 'Photograph Signature, Anything Script_1, Pinyon Script',
    body: 'Mate SC',
		couple: 'Anything Script_1, Pinyon Script',
		title: 'Abhaya Libre, Mate SC'
  },
	colors: {
		mainColorText: '#cf9c2f'
	}
})

export default theme